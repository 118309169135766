<!-- 个人主页布局用 -->
<template>
  <div class="home-page-layout">
    <common-header />
    <div class="router-view">
      <slot name="routerView" />
      <div class="back-top">
        <back-top/>
      </div>
    </div>
    <common-footer />
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="less" scoped>
.home-page-layout {
  min-width: 1430px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  // 路由出口
  .router-view {
    width: 100%;
    flex: 1;
    background: #f5f5f5;
    box-sizing: border-box;
    // 回到顶部
    .back-top{
    @apply fixed;
      bottom: 50px;
      right: 0;
    }
  }
}
</style>